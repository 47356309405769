.alert-box {
  right: 0;
  bottom: 20px;

  padding: 0 20px;
  position: fixed;

  z-index: 9999;
  max-width: 500px;
}
